.quill{
    background-color: #edf2f6;
    font-size: 16px !important;
    font-family: 'Nunito';
    border-radius: 10px;
    border:none !important;

}
.ql-editor{
    font-size: 16px;
    font-family: 'Nunito';

}
.ql-toolbar.ql-snow {
    border:none;
}

.ql-container.ql-snow {
    border:none;
}
 

td{

    border-color: rgb(198, 198, 207) !important;
    border-style: dashed !important;
}
tr:hover{
    
    background-color: #eaf1d6;
}